.login-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(22, 138, 222, 1);
}

.login-panel {
  width: 800px;
  height: 500px;
  box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.1);
  background-color: rgba(34, 154, 230, 1);
  flex-shrink: 0;
}

.login-header {
  background-color:aliceblue;
}
@primary-color: #7DAB8B;