.info-section-title {
  font-size: 14px;
  color: #333333;
  margin-left: 12px;
}

.info-section-blue-block {
  background-color: #4386f5;
  width: 5px;
  height: 14px;
}
@primary-color: #7DAB8B;