
.login-form-item {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}

.login-title {
  font-size: large;
  color: #333333;
  margin-top: 80px;
  margin-left: 20px;
}

.login-form {
  margin-top: 20px;
  width: 80%;
  margin-left: 20px;
}

.forgot-password-text {
  color: #999999;
  padding: 0;
}

.protol-label-text {
  color: #999999;
  padding: 0;
}

.forgot-password-text:hover {
  color: #5491de;
}

.welcome-text {
  font-size: 36px;
  color: #ffffff;
  font-weight: bold;
}
@primary-color: #7DAB8B;