
@import '../../style/theme.less';
.b2b-menu-item-wrapper {
  width: calc(100%);
  height: 50px;
  background-color: #ffffff;
}

.b2b-menu-item {
  width: calc(100%);
  height: 50px;
  padding-left: 18px;
  color: #000000;
  background-color: #f0f2f5;
}

.b2b-menu-item:hover {
  cursor: pointer;
}

.b2b-menu-item-content {
  height: 100%;
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.b2b-menu-item-selected {
  background-color: #ffffff;
  color: @b2b-primary-color;
}

.b2b-menu-item-text {
  font-size: 14px;
  margin-left: 14px;
}


@primary-color: #7DAB8B;