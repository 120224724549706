.b2b-logout-button {
  color: #333333;
  font-size: 16px;
  background-color: #ffffff;
  width: 120px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.b2b-logout-button:hover {
  cursor: pointer;
  background-color: rgba(244, 244, 244, 1);
}

.ant-popover-inner-content {
  padding: 0px;
}

.header-user-info {
  color: #333333;
  font-size: 16px;
}

.header-user-info:hover {
  cursor: pointer;
}
@primary-color: #7DAB8B;