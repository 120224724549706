body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.table-tool-bar {
  width: '100%';
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}

.logo {
  height: 22px;
  width: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 0px 40px 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.1px 2px 0.1px rgba(0,0,0,0.1);
  z-index: 999;
}
@font-face {
  font-family: "iconfont"; /* Project id 3610191 */
  src: url('//at.alicdn.com/t/c/font_3610191_swnuhmb6e8j.woff2?t=1661410395247') format('woff2'),
       url('//at.alicdn.com/t/c/font_3610191_swnuhmb6e8j.woff?t=1661410395247') format('woff'),
       url('//at.alicdn.com/t/c/font_3610191_swnuhmb6e8j.ttf?t=1661410395247') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jianguan:before {
  content: "\e604";
}

.icon-gonghuoshang:before {
  content: "\e605";
}

.icon-shangpin:before {
  content: "\e602";
}

.icon-dingdan:before {
  content: "\e603";
}

.icon-kehu:before {
  content: "\e601";
}

.icon-shouye:before {
  content: "\e600";
}

.app-content {
  padding: 0 12px;
  background-color: #f7f7f7;
}

@primary-color: #7DAB8B;