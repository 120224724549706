@import '../../style/theme.less';
.b2b-main-menu {
  width: 100%;
  height: 100%;
  // background-color: #000000;
}

.sub-menu-panel {
  width: 500px;
  max-height: 500px;
  min-height: 100px;
  background-color: white;
  padding: 16px;
  overflow-y: scroll;
}

/***** SubMenuTitleItem *****/
.sub-menu-title-text {
  color: #333333;
  font-size: 16px;
  margin-top: 16px;
}

.sub-menu-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 16px;
}

.sub-menu-content-item {
  width: 33.33%;
  height: 40px;
  font-size: 14px;
  color: #666666;
}

.sub-menu-content-item-selected {
  color: @b2b-primary-color;
}

.sub-menu-content-item:hover {
  cursor: pointer;
}
@primary-color: #7DAB8B;