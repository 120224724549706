.logo-image {
  width: 40px;
  height: 40px;
}

.header-left-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.b2b-header-title-text {
  color: #333333;
  font-size: 16px;
  margin-left: 10px;
}
@primary-color: #7DAB8B;